.App {
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #333;
  padding: 10px;
  color: white;
}

.App-header ul a {
  color: #D1D1D1;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0;
}

nav li {
  margin-right: 20px;
  width: 70px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

.App-header nav li:hover {
  background-color: #B9382D;
  border-bottom: 4px solid #B9382D;
}

.nav-active {
  border-bottom: 4px solid #B9382D;
}

/**/
.App-footer {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  height: 40px;
  /* 让容器高度填满视口 */
  background-color: #333333;
}

.App-footer ul {
  font-size: 12px;
  color: #D1D1D1;
  width: 600px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  line-height: 100px;
  /* 垂直居中文本 */
}

.App-footer li {
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.App-footer ul a {
  color: #D1D1D1;
}

.news-list {
  margin: 20px;
}

.news-item {
  display: flex;
  margin-bottom: 20px;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.news-item h2 {
  font-size: 18px;
  color: #333;
}

.news-item p {
  font-size: 14px;
  color: #888;
}

.tags {
  margin-top: 10px;
}

.tag {
  display: inline-block;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 3px;
  margin-right: 10px;
  font-size: 12px;
  color: #555;
}

.news-item-img {
  width: 160px;
  height: 120px;
  border: 1px solid gray;
  margin: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-item-img img {
  width: 160px;
  height: 120px;
  object-fit: cover;
}

.post-content {
  margin: 20px 30px;
}

.nav-logo {
  width: 70px;
  background-image: url(logo.svg);
  background-size: contain;
  /* 图片缩放以适应容器，可能会留白 */
  background-position: center;
  /* 图片居中显示 */
  background-repeat: no-repeat;
  /* 不重复图片 */
}

/**AdminSidebar**/
.admin-sidebar {
  border: 1px solid gray;
  width: 300px;
  height: 500px;
  margin-left: 20px;
  margin-top: 10px;
}

.admin-sidebar li {
  height: 30px;
  line-height: 30px;
  color: #333;
  font-size: 14px;
}

.admin-container {
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  align-items: center;
  /* 垂直居中对齐 */
  /* 占满视口高度 */
}

.admin-container-main {
  width: 700px;
  height: 500px;
  margin: 0 10px;
  /* 设置两个 div 之间的间距 */
  background-color: lightblue;
  text-align: center;
  line-height: 100px;
  /* 使文字垂直居中 */
}



/**编辑器样式*/
.posteditor-title {
  float: left;
  width: 100%;
  line-height: 30px;
}

.posteditor-title input {
  width: 600px;
  height: 20px;
  line-height: 20px;
}

.posteditor-content {
  width: 100%;
  height: 400px;
  float: left;
}

.posteditor-content textarea {
  /* width: 620px; */
  width: 96%;
  height: 100%;
}

.posteditor-actionbar {
  height: 30px;
  line-height: 30px;
  float: left;
}

.posteditor-tags {
  float: left;
  width: 100%;
  height: 24px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.posteditor-tags input {
  height: 20px;
  line-height: 20px;
  width: 600px;
  /* float: left; */
}